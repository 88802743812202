import React from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Landing from 'views/landing'
import {
    ChakraProvider,
    withDefaultColorScheme,
    createStandaloneToast,
} from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react'
import customTheme from 'utils/customTheme'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { polygonMumbai } from 'wagmi/chains'
import { PROJECT_ID } from 'utils/const'

export const { toast, ToastContainer } = createStandaloneToast()

const chains = [polygonMumbai]

const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId: PROJECT_ID }),
])
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId: PROJECT_ID, version: 1, chains }),
    publicClient,
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

const theme = extendTheme(
    customTheme,
    withDefaultColorScheme({
        colorScheme: 'primary',
    }),
)

function App() {
    return (
        <ChakraProvider theme={theme}>
            <WagmiConfig config={wagmiConfig}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Landing />} />
                    </Routes>
                </BrowserRouter>
            </WagmiConfig>
            <Web3Modal
                themeVariables={{
                    '--w3m-accent-color': '#F9DA0A',
                    '--w3m-background-color': '#F9DA0A',
                    '--w3m-accent-fill-color': '#000000',
                }}
                projectId={PROJECT_ID}
                ethereumClient={ethereumClient}
            />
            <ToastContainer />
        </ChakraProvider>
    )
}

export default App
